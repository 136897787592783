<template>
  <CCol v-for="(field, key) in customFieldsList" :key="key" class="col-12 custom-field-block">
    <component
      :is="types[field.type]"
      :entity="entity"
      :ref="'children_' + field.type + '_' + key"
      :field="field"
      :form="formFields[field.id]"
      @update="$emit('update')"
    />
  </CCol>
</template>

<script>
import Dropdown from './types/Dropdown'
import DatePicker from './types/DatePicker'
import MultiLineText from './types/MultiLineText'
import SingleLineText from './types/SingleLineText'
import RadioSelect from './types/RadioSelect'
import MultipleCheckboxes from './types/MultipleCheckboxes'
import SingleCheckboxes from './types/SingleCheckboxes'
import SingleLineNumber from './types/SingleLineNumber'
import DropdownMulti from './types/DropdownMulti'

export default {
  name: 'CustomFieldBlock',
  component: {
    Dropdown,
    DatePicker,
    MultiLineText,
    SingleLineText,
    RadioSelect,
    MultipleCheckboxes,
    SingleCheckboxes,
  },
  props: {
    fields: { type: Array, default: () => [] },
    formFields: { type: [Array, Object], default: () => [] },
    type: { type: String, required: true },
    entity: { type: String, required: false }
  },
  emits: ['update', 'delete'],
  data() {
    return {
      types: {
        dropdown_select: Dropdown,
        date_picker: DatePicker,
        multi_line_text: MultiLineText,
        single_line_text: SingleLineText,
        radio_select: RadioSelect,
        multiple_checkboxes: MultipleCheckboxes,
        single_checkbox: SingleCheckboxes,
        single_line_number: SingleLineNumber,
        dropdown_multiselect: DropdownMulti,
      },
    }
  },
  computed: {
    currentType() {
      return this.fields.find((el) => el.name === this.type)
    },
    customFields() {
      return this.currentType?.custom_fields || []
    },
    customFieldsList() {
      return this.type ? this.customFields : this.fields
    }
  },
  methods: {
    handleDelete(id) {
      this.$emit('delete', id)
    },
    handleUpdate(force = false) {
      this.$emit('update', force)
    },
    validateAll() {
      let res = []
      for (const [key, ref] of Object.entries(this.$refs)) {
        if (ref.length) res.push(ref[0].validateAll())
      }

      return res.every((el) => el === true)
    },
  },
}
</script>

<style scoped></style>
