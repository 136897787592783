<template>
  <ConfirmDialog ref="confirmDialog" />
  <CRow v-for="(item, index) in options" :key="index" :xs="{ cols: 2, gutterX: 0 }" class="my-3">
    <CCol class="col-10">
      <CNInput
        v-model="options[index]['value']"
        required
        :label="`Option ${index + 1}`"
        :invalid="!!validationErrors[index]"
        :error="validationErrors[index]"
        @blur="handleBlurOption(index)"
      />
    </CCol>
    <CCol class="col-2 mt-2 text-center">
      <CButton class="deleteItemList text-error" variant="ghost" @click="handleRemoveOption(index)">
        <CIcon name="cilX" />
      </CButton>
    </CCol>
  </CRow>

  <CRow :xs="{ cols: 2, gutterX: 0 }" class="my-3">
    <CCol class="col-10">
      <CNInput :label="`Option ${options.length + 1 }`" @blur="handleAddOption" />
    </CCol>
    <CCol class="col-2 mt-2 text-center">
      <CButton class="deleteItemList text-error" variant="ghost" disabled>
        <CIcon name="cilX" />
      </CButton>
    </CCol>
  </CRow>
</template>

<script>
// TODO fix all options (Options, Stages and Owners) to Component
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'

export default {
  name: 'Options',
  components: { ConfirmDialog },
  props: {
    fieldId: { type: Number, default: () => null },
    options: { type: Array, default: () => [] },
  },
  data() {
    return {
      usage: {
        index: undefined,
        projects: [],
      },
      validationErrors: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.setRules()
        this.validateAll()
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setRules()
    })
  },
  methods: {
    async confirmDelete() {
      await this.$refs.confirmDialog
        .confirm({
          text: 'Delete option',
          info: `It is impossible to delete value "${this.options[this.usage.index]['value']}" because it is selected in projects: ${this.usage.projects.join(', ')}.`,
          confirmText: 'OK',
          onlyConfirmButton: true,
        })
        .then(() => {
          this.validationErrors[this.usage.index] = undefined
          this.usage = {
            index: undefined,
            projects: [],
          }
        })
    },
    validateAll() {
      this.setRules()
      const validationResult = this.validator.validateAll(this.options.map((el) => el.value))
      this.validationErrors = this.$deepClone(validationResult.validationErrors)
      return !validationResult.hasErrors
    },
    setRules() {
      let baseRules = {}
      if (this.options.length) {
        this.options.forEach((el, index) => {
          baseRules[index] = [
            rules.required,
            rules.strMax(255),
            rules.unique(this.options.map((el) => el.value).filter((o, i) => i !== index)),
          ]
        })
      }

      this.validator = validator(baseRules)
    },
    handleAddOption(val) {
      if (val) this.options.push({ value: val })
    },
    handleBlurOption(index) {
      this.validationErrors[index] = this.validator.validate(index, this.options[index]['value'])
    },
    handleRemoveOption(index) {
      if (!this.fieldId || !this.options[index]['origin']) {
        this.removeOption(index)
      } else {
        if (this.options[index]['origin']) {
          this.options[index]['value'] = this.options[index]['origin']
        }
        this.$http.projects.isUsingOption({
            params: {
              field_id: this.fieldId,
              project_id: this.$route.params.id || undefined,
              value: this.options[index]['origin'],
            },
          }).then(({ data }) => {
            if (data.is_using) {
              this.visible = true
              this.usage = {
                index: index,
                projects: data.projects
              }
              this.validationErrors[index] = `It is impossible to delete value "${this.options[index]['origin']}" because it is selected in projects`
              this.confirmDelete()
            } else {
              this.removeOption(index)
            }
          })
      }
    },
    removeOption(index) {
      this.options.length === 1
        ? this.options[index] = ''
        : this.options.splice(index, 1)

      this.validationErrors[index] = undefined
      this.setRules()
    },
  },
}
</script>

<style scoped>

</style>
