import { defineComponent } from "vue"
import { mapGetters, mapMutations } from 'vuex'

export default defineComponent({
  beforeMount() {
    window.addEventListener('beforeunload', this.eventRelolad)

    // window.onbeforeunload = this.eventRelolad
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.eventRelolad)

    // window.onbeforeunload = function () {}
  },
  data() {
    return {
      closeModal: false,
      updateModal: false
    }
  },
  watch: {
    updateModal(to, from) {
      this.setUpdateModal(this.updateModal)
    }
  },
  methods: {
    ...mapMutations(['setUpdateModal']),
    eventRelolad(e) {
      if (typeof this.updateModal == "boolean") {
        if (this.updateModal) {
          e.preventDefault();
          e.returnValue = '';
        }
      }
    }
  }
})
