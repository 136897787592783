<template>
  <CCol class="ProjectCustomFieldNew p-3">

    <ConfirmDialog ref="confirmDialog" />

    <CRow :xs="{ cols: 1, gutter: 3 }" :sm="{ cols: 2, gutter: 3 }">
      <CCol class="col-12 col-xl-9">
        <CNInput
          v-model="customField.label"
          label="New Custom Field"
          required
          :error="validationErrors.label"
          :invalid="!!validationErrors.label"
          variant="ghost"
          @blur="validateField('label')"
        />
      </CCol>
      <CCol
        v-if="
          customField.type !== 'single_checkbox' &&
          customField.type !== 'radio_select' &&
          customField.type !== 'multiple_checkboxes'
        "
        class="col-12 col-xl-3"
      >
        <CRow class="m-0 mt-2 text-end justify-content-end w-100">
          <CCol class="col-auto p-0">
            <CNCheckbox v-model="customField.is_required" />
          </CCol>
          <CCol class="col-auto p-0 text-disabled">
            <span>Required</span>
          </CCol>
        </CRow>
      </CCol>
      <CCol>
        <CNSelect
          v-model="customField.type"
          label="Field Type"
          required
          :options="types"
        />
      </CCol>
      <CCol>
        <CNInput
          v-model="customField.placeholder"
          label="Placeholder"
          :required="!disabledPlaceholder.includes(customField.type)"
          :disabled="disabledPlaceholder.includes(customField.type)"
          :error="validationErrors.placeholder"
          :invalid="!!validationErrors.placeholder"
          @blur="validateField('placeholder')"
        />
      </CCol>
      <CCol v-if="visibleOptions" class="col-12">
        <Options ref="options" :options="customField.opt" :field-id="form.id" />
      </CCol>
      <CCol class="col-12 pe-0">
        <CRow
          :xs="{ gutterY: 3 }"
          :xl="{ gutterY: 0 }"
          class="justify-content-between w-100"
        >
          <CCol class="col-12 col-xl-auto">
            <CButton
              class="w-100"
              color="primary"
              variant="outline"
              :disabled="loading"
              @click="cancel"
              >Cancel</CButton
            >
          </CCol>
          <CCol class="col-12 col-xl-auto">
            <CButton
              class="w-100"
              color="primary"
              :disabled="loading"
              @click="save"
              >Save</CButton
            >
          </CCol>

          <CCol class="col-12 col-xl-auto ms-auto pe-0">

            <CButton
              v-if="customField.id"
              variant="ghost"
              class="w-100 text-error pe-xl-0"
              :disabled="loading"
              @click.capture.stop.prevent="onDelete"
            >
              <CIcon name="cilTrash" />
              Delete field
            </CButton>

            <CButton v-else variant="ghost" class="w-100 text-disabled pe-xl-0">
              <CIcon name="cilTrash" />
              Delete field
            </CButton>

          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <CModal :visible="!!deleteModal" @close="deleteModal = false">
      <CModalHeader>
        <CModalTitle>Delete field</CModalTitle>
      </CModalHeader>
      <CModalBody v-if="!deleteModalInfo">
        <h6>Are you sure, you want to delete the "{{ form.label }}" field?</h6>
        <p>
          It will be deleted from all Project Details Forms with currently
          selected data
        </p>
      </CModalBody>
      <CModalBody v-else>
        <h6>Are you sure, you want to delete the Section with fields?</h6>
        <p class="mb-0">
          Next templates contain the {{ form.shortcode }} Merge field:
        </p>
        <ul style="list-style-type: none">
          <li
            v-for="(template, key) in deleteModalInfo"
            :key="key"
            style="color: #005d9d"
          >
            <router-link
              :to="{ name: 'TemplateEditor', params: { id: template.id } }"
              target="_blank"
              >{{ template.template_name }}</router-link
            >
          </li>
        </ul>
        <p>
          <strong>Recommendation: </strong> to delete Merge fields manually from
          templates first, to avoid empty spaces in contracts.
        </p>
      </CModalBody>
      <CModalFooter v-if="!deleteModalInfo">
        <CButton
          color="primary"
          variant="outline"
          @click="
            () => {
              deleteModal = false
            }
          "
        >
          Cancel
        </CButton>
        <CButton color="primary" @click="deleteField(true)">Delete</CButton>
      </CModalFooter>
      <CModalFooter v-else>
        <CButton
          color="primary"
          variant="outline"
          @click="
            () => {
              deleteModal = false
            }
          "
        >
          Cancel
        </CButton>
        <CButton color="primary" @click="deleteField(true)"
          >Delete, and remove all merge fields automatically</CButton
        >
      </CModalFooter>
    </CModal>
  </CCol>
</template>

<script>
import reloadData from '@/mixin/reloadData'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import Options from './Options'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import { isEqual } from 'lodash/lang'
import errors from '@/mixin/errors'

export default {
  components: { Options, ConfirmDialog },
  mixins: [reloadData, errors],
  inject: ['toast'],
  props: {
    form: { type: Object, default: () => ({}) },
    sectionId: { type: Number, require: true },
    entity: { type: String, required: false }
  },
  emits: ['update', 'cancel', 'changeType'],
  data: () => ({
    deleteModal: false,
    deleteModalInfo: null,
    hasOptions: [
      'multiple_checkboxes',
      'radio_select',
      'dropdown_select',
      'dropdown_multiselect',
    ],
    dontHasRequired: ['single_checkbox', 'radio_select', 'multiple_checkboxes'],
    disabledPlaceholder: ['radio_select', 'multiple_checkboxes'],
    loading: false,
    types: [
      { value: 'single_line_text', name: 'Single line text' },
      { value: 'multi_line_text', name: 'Multi line text' },
      { value: 'dropdown_select', name: 'Dropdown select' },
      { value: 'single_checkbox', name: 'Single checkbox' },
      { value: 'date_picker', name: 'Date picker' },
      //
      // { value: 'single_line_number', name: 'Single line number' },
      // { value: 'multiple_checkboxes', name: 'Multiple checkboxes' },
      // { value: 'radio_select', name: 'Radio select' },
      // { value: 'dropdown_multiselect', name: 'Dropdown multiselect' },
    ],
    validationErrors: {},
    errors: {},
    origin: {},
    customField: {
      is_required: false,
      type: 'dropdown_select',
      label: '',
      shortcode: '',
      placeholder: '',
      options: [],
    },
  }),
  computed: {
    optionsLength() {
      return this.customField.options.length
    },
    visibleOptions() {
      let check = this.hasOptions.includes(this.customField.type)

      if (check && !this.customField.options.length) {
        this.customField.opt = !this.customField.options.length
          ? [{ value: '' }]
          : []
        this.customField.options = !this.customField.options.length ? [''] : []
      }
      return check
    },
  },
  watch: {
    'customField.type': function (val) {
      if (this.dontHasRequired.includes(this.customField.type)) {
        this.customField.is_required = false
      }
      if (!this.hasOptions.includes(val)) {
        this.customField.options = []
      }
      if (this.disabledPlaceholder.includes(this.customField.type)) {
        this.customField.placeholder = ''
        this.validateField('placeholder')
      }
    },
    'customField.label': function () {
      if (this.customField.label) {
        this.customField.shortcode = `{{${this.customField.label}}}`
      } else {
        this.customField.shortcode = '{{Field label}}'
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (Object.keys(this.form).length) {
        this.customField = this.$deepClone(this.form)
        if (!this.hasOptions.includes(this.form.type)) {
          this.customField.options = []
        }
      }
      setTimeout(() => (this.origin = this.$deepClone(this.customField)), 50)
      this.setRules()
    })
  },
  methods: {
    onDelete() {
      this.handleDelete()
    },
    setRules() {
      this.validator = validator({
        label: [rules.required, rules.strMax(50)],
        placeholder: [
          () =>
            this.disabledPlaceholder.includes(this.customField.type) ? -1 : 0,
          rules.required,
          rules.strMax(50),
        ],
        type: [rules.required],
      })
    },
    validateField(f) {
      this.validationErrors[f] = this.validator.validate(f, this.customField[f])
    },
    create(data) {
      const section_id = data.section_id;

      if (section_id) {
        this.$http.projects
          .createProjectField(data)
          .then((res) => {
            this.toast('success', res.data.message)
            this.$emit('update')
          })
          .catch(({ response }) => {
            this.validationErrors = response.data.errors
              ? this.getErrors(response.data.errors)
              : {}
            this.toast('error', response.data.message)
          })
          .finally(() => (this.loading = false))
      } else {

        const payload = {
          "entity": "scope",
          "type": data.type,
          "label": data.label,
          "placeholder": data.placeholder,
          "is_required": data.is_required
        }

        if (data.opt && data.options) {
          payload.opt = data.opt;
          payload.options = data.options;
        }

        this.$http.scope.storeCustomFields(payload)
          .then((res) => {
            this.toast('success', res.data.message)
            this.$emit('update')
          })
          .catch(({ response }) => {
            this.validationErrors = response.data.errors
              ? this.getErrors(response.data.errors)
              : {}
            this.toast('error', response.data.message)
          })
          .finally(() => (this.loading = false))
      }
    },
    edit(data) {
      if (this.sectionId) {

        this.$http.projects
          .editProjectField(data)
          .then((res) => {
            this.toast('success', res.data.message)
            this.$parent.$parent.$parent.handleUpdate(data.force || false)
            if (this.origin.type !== this.customField.type)
              this.$emit('changeType')
            this.$emit('cancel')
          })
          .catch(({ response }) => {
            this.validationErrors = response.data.errors
              ? this.getErrors(response.data.errors)
              : {}

            if (response.status === 409) {
              this.confirmEdit(data, response.data)
            } else if (response.status !== 422) {
              this.toast('error', response.data.message)
            }
          })
          .finally(() => (this.loading = false))
      } else {

        const payload = {
          "type": data.type,
          "label": data.label,
          "placeholder": data.placeholder,
          "is_required": data.is_required
        }

        if (data.opt && data.options) {
          payload.opt = data.opt;
          payload.options = data.options;
        }

        this.$http.scope.updateCustomField(payload, this.customField.id)
          .then(res => {
            this.toast('success', res.data.message)
            this.updateData();
            this.$emit('cancel')
          })
          .catch(({ response }) => {
            this.validationErrors = response.data.errors
              ? this.getErrors(response.data.errors)
              : {}

            if (response.status === 409) {
              this.confirmEdit(data, response.data)
            } else if (response.status !== 422) {
              this.toast('error', response.data.message)
            }
          })
          .finally(() => (this.loading = false))
      }

    },
    async confirmEdit(data, using = []) {
      let text = '<div class="text-start">'
      using.forEach((el) => {
        text += `If you edit value ${
          el.edited.origin
        }, it will also change in projects: ${el.projects.join(', ')}. <br><br>`
      })
      text +=
        '<strong>If you want to change the value only for current project, please create a new value.</strong></div>'
      this.loading = true
      await this.$refs.confirmDialog
        .confirm({
          text: `Edit Custom field`,
          info: text,
          cancelText: 'Cancel',
          confirmText: 'Confirm',
          reverse: true,
          size: 'xl',
        })
        .then((res) => {
          if (res) {
            data.force = true
            this.edit(data)
          } else {
            this.loading = false
          }
        })
    },
    async save() {
      const validateOptions = this.$refs?.options
        ? await this.$refs?.options?.validateAll()
        : true
      const validationResult = this.validator.validateAll(this.customField)
      if (!validationResult.hasErrors && validateOptions) {
        this.loading = true
        let data = this.$deepClone(this.customField)
        data.section_id = this.sectionId
        data.field_id = this.customField.id || undefined

        data.options = this.customField.options.length
          ? this.customField.opt.map((el) => el.value)
          : undefined

        data.edited = this.customField.options.length
          ? this.customField.opt
              .map((el) => {
                if (el.origin && el.origin !== el.value) {
                  return el
                }
              })
              .filter((el) => typeof el === 'object')
          : undefined

        data.field_id ? this.edit(data) : this.create(data)
      } else {
        this.validationErrors = this.$deepClone(
          validationResult.validationErrors,
        )
      }
    },
    async cancel() {
      if (!isEqual(this.customField, this.origin)) {
        await this.$refs.confirmDialog
          .confirm({
            text: this.$t('messages.unsaved'),
            cancelText: 'Cancel',
            confirmText: 'OK',
          })
          .then((res) => {
            if (res) this.$emit('cancel')
          })
      } else {
        this.$emit('cancel')
      }
    },

    async handleDelete() {

      await this.$refs.confirmDialog
        .confirm({
          text: `Are you sure you want to delete the "${ this.customField.label }" field?`,
          info: `It will be deleted from all Project Details Forms with currently selected data`,
          cancelText: 'Cancel',
          confirmText: 'Delete',
          reverse: true,
        })
        .then((res) => {
          if (res) {
            this.deleteField(false)
          }
        })
    },
    updateData() {
      this.$emit('update');
    },
    deleteField(force = false) {
      this.deleteModalInfo = null

      if (this.sectionId) {
        this.$http.projects
          .deleteProjectField({
            field_id: this.customField.id,
            force_delete: force,
          })
          .then((res) => {
            if (res.data.status === 'used') {
              this.deleteModalInfo = res.data.shortcode_usage
              this.deleteModal = true
            } else if (res.data.status === 'deleted') {
              this.toast(
                'success',
                'The custom field has been successfully deleted',
              )
              this.$parent.$parent.$parent.handleDelete(this.customField.id)
              this.$emit('cancel')
            } else {
              this.deleteField(true)
            }
          })
          .finally(() => (this.loading = false))
      } else {
        this.$http.scope.deleteCustomField(this.customField.id)
          .then(res => {
            this.toast('success', res.data.message);
            this.updateData();
          })
          .finally(() => (this.loading = false))
      }
    },
  },
}
</script>

<style lang="scss">
.ProjectCustomFieldNew {
  background: #f5f7f9;
  .cn-form-input-ghost > input {
    background: #f5f7f9;
  }
}
</style>
