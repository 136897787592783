<template>
  <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate" @changeType="changeType" @cancel="show = false" />
  <CRow v-else>
    <CFormLabel for="staticEmail" class="col-sm-4 col-form-label">
      {{ field.label }}
      <span v-if="field.require" style="color: red">*</span>
      <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
    </CFormLabel>
    <div class="col-sm-8 col-form-label">
      <CFormCheck
        v-for="(item, index) in field.options"
        :key="`${item}-${index}`"
        type="radio"
        :label="item"
        :disabled="disabled"
        :checked="form.values === item"
        @click="setCheck(item)"
      />
      <CFormText v-if="error" style="color: red" class="text-error">
        {{ error }}
      </CFormText>
    </div>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  props: {
    error: { type: String, default: '' },
  },
  watch: {
    'field.values': function () {
      if (typeof this.form.values !== 'string') this.form.values = ''
    },
  },
  mounted() {
    if (typeof this.form.values !== 'string') this.form.values = ''
  },
  methods: {
    setCheck(item) {
      this.form.values = item
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
