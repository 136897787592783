<template>
  <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate" @changeType="changeType" @cancel="show = false" />
  <CRow v-else>
    <CFormLabel class="col-sm-4 col-form-label">
      {{ field.label }}
      <span v-if="field.is_required" style="color: red">*</span>
      <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
    </CFormLabel>
    <div class="col-sm-8">
      <slot v-if="$slots.input" name="input" />
      <div v-else class="ProjectDetailsForm__number">
        <CFormInput
          :value="form.values"
          class="ProjectDetailsForm__number-input"
          type="number"
          :placeholder="field.placeholder"
          :disabled="disabled"
          v-bind="readonly ? { readonly: true, plainText: true } : {}"
          style="background: white"
          :style="`border-color: ${validationErrors.values ? 'red' : ''}`"
          @input="changeValue"
          @blur="validateField"
        />
        <CIcon name="cilChevronTop" @click="add(1)" class="ProjectDetailsForm__number__arrow ProjectDetailsForm__number__arrow-top"/>
        <CIcon name="cilChevronBottom" @click="add(-1)" class="ProjectDetailsForm__number__arrow ProjectDetailsForm__number__arrow-bottom"/>
      </div>
      <CFormText v-if="validationErrors.values" style="color: red" class="text-error">{{ validationErrors.values }}</CFormText>
    </div>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  watch: {
    'field.values': function () {
      if (typeof this.form.values !== 'number') this.form.values = null
    },
  },
  mounted() {
    if (typeof this.form.values !== 'number') this.form.values = null
  },
  methods: {
    changeValue(event) {
      this.form.values = parseInt(event.target.value.replace(/\D/g, '')) || null
    },
    add(value) {
      this.form.values = parseInt(this.form.values || 0) + value
    },
  },
}
</script>

<style lang="scss" scoped>
.ProjectDetailsForm__number {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;

  &__arrow {
    position: absolute;
    right: 5px;
    cursor: pointer;
    height: 12px!important;

    &-top {
      top: 5px;
    }

    &-bottom {
      bottom: 5px;
    }
  }

  &-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      opacity: 0;
      -webkit-appearance: none;
    }
  }
}
</style>
