import CustomFieldForm from '../CustomFieldForm'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { mapGetters } from 'vuex'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  mixins: [],
  inject: ['toast'],
  props: {
    entity: { type: String, default: '' },
    form: { type: Object, default: {} },
    readonly: { type: Boolean, default: false },
    error: { type: String, default: '' },
    field: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          is_required: false,
          label: '',
          options: [],
          placeholder: '',
          section_id: undefined,
          shortcode: '',
          type: 'dropdown_select',
        }
      },
    },
  },
  components: { CustomFieldForm },
  data() {
    return {
      show: false,
      validationErrors: {},
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
    }),
    disabled() {
      return !this.permissions.editOrCreate
    },
  },
  watch: {
    'field.is_required': function () {
      this.setRules()
      this.validateField()
    },
  },
  mounted() {
    this.setRules()
  },
  methods: {
    handleUpdate() {
      this.$emit('update')
    },
    validateAll() {
      const validationResult = this.validator.validateAll(this.form)
      this.validationErrors = this.$deepClone(validationResult.validationErrors)
      return !validationResult.hasErrors
    },
    setRules() {
      let obj = {}
      if (this.field.is_required) {
        obj = { values: [rules.required] }
      }
      this.validator = validator(obj)
    },
    validateField() {
      this.validationErrors['values'] = this.validator.validate('values', this.form['values'])
    },
    changeType() {
      this.form.values = undefined
    },
    handleShow() {
      !this.permissions.editOrCreate
        ? this.$notify(permissionError)
        : this.show = true
    },
  },
}
