const regexpEmail =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const required = (value) => (value && value != 0 ? null : 'Field is required')
const requiredArray = (value) =>
    value && value.length ? null : 'Field is required'
const requiredBoolean = (value) =>
    value === true || value === false ? null : 'Field is required'
const requiredDate = (value) =>
    `${value}` !== 'Invalid date' && value ? null : 'Field is required'
const email = (value) =>
    value
        ? regexpEmail.test(value.toLowerCase())
            ? null
            : 'Incorrect email format'
        : null
const website = (value) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i',
    ) // fragment locator
    return value
        ? pattern.test(value)
            ? null
            : 'Incorrect website format'
        : null
}
const max = (value, max) =>
    value ? (value.length <= max ? null : `Maximum field length ${max}`) : null
const min = (value, min) =>
    value ? (value.length >= min ? null : `Minimum field length ${min}`) : null
const zip = (value) =>
    value
        ? value.length === 5 || value.length === 10
            ? null
            : `ZIP code is in the wrong format`
        : null
const exceptSymbol = (value, params = []) => {
    let symbolFind = false
    params.forEach((s) => {
        if (value.indexOf(s) !== -1) {
            symbolFind = true
        }
    })
    return symbolFind ? 'Field contains invalid characters' : null
}

export default {
    data() {
        return {
            errors: {},
            rule: {
                required,
                requiredArray,
                requiredDate,
                requiredBoolean,
                email,
                website,
                max,
                min,
                zip,
                exceptSymbol,
            },
            ruleFields: [],
        }
    },
    methods: {
        getErrors(errors) {
            let err = {}
            Object.keys(errors).forEach((item) => {
                err[item] = errors[item][0]
            })
            this.scrollToError()
            return err
        },
        scrollToError() {
            let scroll = true
            setTimeout(() => {
                document.querySelectorAll('.form-text').forEach((i) => {
                    const top = i.getBoundingClientRect().top
                    const y = top + window.pageYOffset + -150

                    if (i.style.color === 'red' && scroll && top) {
                        window.scrollTo({top: y, behavior: 'smooth'})
                        scroll = false
                    }
                })
            }, 1)
        },
        validate(key) {
            const single = this.ruleFields.find((item) => item.key === key)
            this.$nextTick(() => {
                this.$emit('uiUpdated')
            })
            return this.validateFields(single ? [single] : this.ruleFields)
        },
        validateFields(fields) {
            fields.forEach((field) => {
                this.errors[field.key] = null
                field.rules.forEach((rule) => {
                    let value = this[field.key]
                        ? this[field.key]
                        : this.byString(this, field.key)
                    if (rule.name === 'custom') {
                        if (rule.function(value, rule.params)) {
                            this.errors[field.key] = rule.function(value, rule.params)
                        }
                    } else {
                        if (this.rule[rule.name](value, rule.params)) {
                            this.errors[field.key] = this.rule[rule.name](value, rule.params)
                        }
                    }
                })
            })
            if (fields.length > 1) {
                this.scrollToError()
            }
            return Object.values(this.errors).filter((i) => !!i).length === 0
        },
        byString2(oo, ss) {
            let o = oo
            let s = ss.replace(/\[(\w+)\]/g, '.$1')
            s = s.replace(/^\./, '')
            let a = s.split('.')
            for (let i = 0, n = a.length; i < n; ++i) {
                let k = a[i]
                if (k in o) {
                    o = o[k]
                } else {
                    return
                }
            }
            return o
        },
        byString(o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1')
            s = s.replace(/^\./, '')
            var a = s.split('.')
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i]
                if (k in o) {
                    o = o[k]
                } else {
                    return
                }
            }
            return o
        },
    },
}
