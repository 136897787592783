<template>

  <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate" @changeType="changeType" @cancel="show = false" />

  <CRow v-else>
    <CFormLabel for="staticEmail" class="col-sm-4 col-form-label">
      {{field.label}}
      <span v-if="field.is_required" style="color: red">*</span>
      <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
    </CFormLabel>
    <div class="col-sm-8">
      <slot v-if="$slots.input" name="input" />
      <div v-else style="display: flex; align-items: center; white-space: nowrap;">
        <CFormInput
          v-if="mask.length === 0"
          :placeholder="field.placeholder"
          :value="form.values"
          :disabled="disabled"
          style="background: white"
          v-bind="readonly ? { readonly: true, plainText: true } : {}"
          :style="`border-color: ${validationErrors.values ? 'red' : ''}`"
          @input="changeValue"
          @blur="validateField"
        />
        <CFormInput
          v-else
          :placeholder="field.placeholder"
          v-mask="mask"
          v-bind="readonly ? { readonly: true, plainText: true } : {}"
          :style="`border-color: ${validationErrors.values ? 'red' : ''}`"
          @input="changeValue"
        />
        <CFormText v-if="field.sublabel" :style="`margin-left: 5px`">
          {{ field.sublabel }}
        </CFormText>
      </div>
      <CFormText v-if="validationErrors.values" style="color: red" class="text-error">
        {{ validationErrors.values }}
      </CFormText>
    </div>
  </CRow>
</template>

<script>
import { mask } from 'vue-the-mask'
import mixin from './mixin'
export default {
  directives: { mask },
  mixins: [mixin],
  props: {
    mask: { type: Array, default: [] },
  },
  watch: {
    'field.values': function () {
      if (typeof this.form.values !== 'string') this.form.values = ''
    },
  },
  mounted() {
    if (typeof this.form.values !== 'string') this.form.values = ''
  },
  methods: {
    changeValue(event) {
      this.form.values = event.target.value
    },
  },
}
</script>
