<template>
  <CustomFieldForm v-if="show" :entity="entity" :form="field" :section-id="field.section_id" @update="handleUpdate" @changeType="changeType" @cancel="show = false" />
  <CRow v-else>
    <CFormLabel for="staticEmail" class="col-sm-4 col-form-label">
      <span v-if="field.is_required" style="color: red">*</span>
      <CIcon name="cil-pen" style="margin-left: 15px; cursor: pointer" @click="handleShow"/>
    </CFormLabel>
    <div class="col-sm-8 col-form-label">
      <CFormCheck
        v-for="(item, index) in field.options"
        :key="`${item}-${index}`"
        :label="item"
        :disabled="disabled"
        :checked="getCheck(item)"
        @click="setCheck(item)"
      />
      <CFormText v-if="error" :style="`color: ${error && 'red'}`" class="text-error">{{ error }}</CFormText>
    </div>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  props: {
    error: { type: String, default: '' },
  },
  watch: {
    'field.values': function () {
      if (!Array.isArray(this.form.values)) this.form.values = []
    },
  },
  mounted() {
    if (!Array.isArray(this.form.values)) this.form.values = []
  },
  methods: {
    getCheck(item) {
      if (this.form.values && this.form.values.length && Array.isArray(this.form.values)) {
        return !!this.form.values.find(i => i == item)
      } else {
        return false
      }
    },
    setCheck(item) {
      if (this.getCheck(item)) {
        this.form.values = this.form.values.filter(i => i !== item)
      } else {
        this.form.values = [...(this.form.values || []), item]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
